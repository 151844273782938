<template>
  <div class="d-flex justify-center flex-nowrap">
    <v-btn
      :to="useLocalePrefixForHref('/registration')"
      :color="scssVariables.jColorPrimary"
      variant="flat"
      size="large"
      class="text-none text-body-1 px-6 px-lg-5"
    >
      {{ $t('general_registration') }}
    </v-btn>
    <v-btn
      :to="useLocalePrefixForHref('/login')"
      variant="flat"
      size="large"
      class="text-none text-body-1 px-9 px-lg-5 j-btn j-btn--gradient-main ml-2 ml-lg-4"
    >
      <template #prepend>
        <v-img
          :width="16"
          :src="images['auth_shield']"
          alt="icon auth-shield"
        />
      </template>
      {{ $t('general_enter') }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
const scssVariables = useScssVariables();
const images = useAssetsImages();
</script>
